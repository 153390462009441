<script setup lang="ts">
    // import {NotificationContainer} from '@deegital/vue-trustup-io-toasteo';
    const { isLoading, loading, load } = useLoadingScreen(useRoute());

    onMounted(async () => {
        if (load) {
            await load();
        }
    });
</script>

<template>
    <div class="">
        <transition
            leave-active-class="duration-300 ease-in"
            leave-from-class="opacity-100 blur-none"
            leave-to-class="opacity-0 blur-xl"
        >
            <LayoutLoadingScreen
                v-if="isLoading"
                :loading="loading"
            />
        </transition>
        <template v-if="!isLoading">
            <div class="relative flex items-stretch w-full h-screen min-h-[800px] nice-scrollbar">
                <div class="absolute top-0 right-0 z-10 px-4 py-4 lg:left-0 lg:right-auto lg:bottom-0 lg:top-auto">
                    <LanguageSwitcher
                        submenu-position="right-0 lg:right-auto lg:left-0 lg:bottom-full lg:top-auto lg:mb-1"
                        :key="useGetCurrentLocaleObject()?.locale ?? 'be-fr'"
                    >
                        <template v-slot:default="slotProps">
                            <div
                                class="flex items-center p-2 space-x-2 text-sm text-gray-600 transition-all bg-white border border-gray-100 rounded cursor-pointer hover:bg-neutral-100 active:bg-neutral-200 hover:text-primary group"
                                @click.prevent="slotProps.toggle()"
                            >
                                <img
                                    class="h-[16px] bg-neutral-100 min-w-[18px]"
                                    :src="`https://trustup-io-shared-assets.ams3.cdn.digitaloceanspaces.com/images/translations/flags/${useGetCurrentLocaleObject()?.country}.svg`"
                                    alt=""
                                />
                                <span>
                                    {{ $t(`locales.native.${useGetCurrentLocaleObject()?.language}`) }}
                                </span>
                            </div>
                        </template>
                    </LanguageSwitcher>
                </div>
                <div class="relative items-center hidden w-1/2 overflow-hidden lg:block">
                    <!-- <div class="absolute top-0 left-0 pt-8 pl-8">
                        <img src="~/assets/logo_white_full.svg" class="h-[30px]" />
                    </div> -->
                    <img
                        src="~/assets/login_bg.jpg"
                        class="object-cover w-full h-full"
                    />
                </div>
                <div class="relative flex items-center justify-center w-full p-6 bg-white lg:w-1/2 dark:bg-black md:p-8 lg:p-12">
                    <div class="absolute top-0 left-0 right-0 flex justify-center block px-4 pt-6 md:pt-8">
                        <img
                            src="~/assets/logo.svg"
                            class="h-[24px]"
                        />
                    </div>
                    <div class="w-full max-w-[500px]">
                        <slot></slot>
                    </div>
                </div>
            </div>
            <client-only>
                <!-- <NotificationContainer /> -->
            </client-only>
        </template>
    </div>
</template>
